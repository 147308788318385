<template>
    <div class="lvya-aboutUS">
        <div class="about-div1">
            <img src="static/lvya/aboutUS/about1.jpg" alt="" style="width:100%;height:100%;">
        </div>
        <div class="about-div2">
            <div class="tip-div">
                <p class="tip-top">公司介绍</p>
                <p class="tip-center">COMPANY&nbsp;INTRODUCTION</p>
                <p class="tip-line"></p>
            </div>
            <div class="gsjs">
                <img src="static/lvya/aboutUS/about2.png" alt="" class="gsjs-img">
                <div>
                    <p style="font-size: 15px; line-height: 26px; text-align: left; margin-left: 20px;">
                        <!-- 金岭教育是国内首家面向未来教育顶层设计和教育改革的开放式智慧校园云平台，首家把立德树人核心素养国家教育战略纳入产品及服务解决方案并实现学校落地，
                        产品方案同时被多个省市级教育主管部门采纳并写入政府教育规划文件。<br/>
                        <span style="height: 15px;display:block;"></span>
                        公司经过6年多的发展，目前已实现在国内幼教、基教、和高教职教各阶段的产品全覆盖，包括数百家国家级省级重点学校。
                        覆盖用户数千万（典型客户如：沈阳市教育局、新疆生产建设兵团第二师教育局、北京十一学校、人大附中、北京第二实验小学、上海市长宁区天山第一小学 、江苏天一中学、郑州八中，新疆华山中学、湖南汽车工程职业学院、天津大学等）。<br/>
                        <span style="height: 15px;display:block;"></span>
                        金岭教育研究院联合教育资深专家和教育机构，深度聚焦国际先进教育理念，结合国内实际教育发展环境，融合探究，利用信息技术，
                        不断努力探寻能够适应未来教育高质量发展与提升的整体解决方案。近期发布最新成果“素养地图”小册子，旨在帮助学校探索落实“新课标”、建立教学评一体化的实施路径。<br/>
                        <span style="height: 15px;display:block;"></span>
                        我们坚信唯有全情投入，才能突破界限，创造奇迹。我们既能仰望星空，亦能脚踏实地。
                        我们坚信技术的价值，唯有用好技术，才能真正推动教育范式的改变。
                        我们就是带着这样的信仰和梦想，做出了最受学校、老师和家长欢迎的平台级教育产品与服务。<br/>
                        <span style="height: 15px;display:block;"></span>
                        金岭智慧校园平台：提供智慧校园师生成长服务。 -->
                        金岭教育是一家专门为教育主管部门和学校提供教育内涵发展实现的技术工具和方法论的教育科技公司，把立德树人核心素养国家教育战略纳入产品及服务解决方案并推动学校落地。公司以学校未来教育的实现为目标，构筑了完整的数字化教育管理和实证性评估体系，科学赋能学校落实新课改的专业能力，实现学校从粗犷发展向内涵发展的转变，为客户提供面向未来教育顶层设计和支撑教育改革的开放式智慧校园云平台。
                        <span style="height: 15px;display:block;"></span>
                        近十年来，公司深耕教育，深入研究了世界各国教育改革发展历程，公司的教学法专家团队掌握了各种先进教育理念的落地方法，技术团队把素质教育的方法抽象成了工具，用工具+咨询的方式，赋能学校实现内涵式发展。公司的产品方案也多次被各地省市级教育主管部门认可采纳并写入政府教育规划文件。定位于全球领先的教育信息化服务商，公司立足北京总部，辐射全国，已实现在国内基础教育、职教到高教各阶段学校全覆盖，落地学校数千所，为全国一半省市头部名校提供优质的教育质量保障服务。 覆盖用户数千万（典型客户如：沈阳市教育局、新疆生产建设兵团第二师教育局、北京十一学校、人大附中、北京第二实验小学、上海市长宁区天山第一小学 、江苏天一中学、郑州八中，新疆华山中学、湖南汽车工程职业学院、天津大学等）。
                        <span style="height: 15px;display:block;"></span>
                        我们坚信唯有全情投入，才能突破界限，创造奇迹。我们既能仰望星空，亦能脚踏实地。 我们坚信技术的价值，唯有用好技术，才能真正推动教育范式的改变。 我们就是带着这样的信仰和梦想，做出了受学校、老师和家长欢迎的平台级教育产品与服务。
                    </p>
                </div>
            </div>
            <div class="about-div1" style="margin-top: 60px;"><img src="static/lvya/aboutUS/qywh.png" alt="" style="width: 100%; height: 100%;"></div>
            <div class="yj"><div class="yj-left"><div style="text-align: center;"><img src="static/lvya/aboutUS/yj (2).png" alt="" class="yj-icon"><p style="font-size: 20px; margin: 20px 0px 10px;">愿景</p><p style="width: 50px; height: 3px; background: rgb(25, 202, 129); margin: 0px auto;"></p></div><div><p style="line-height: 45px; color: rgb(102, 102, 102);"> 运用技术，让中国孩子从繁重低效的学习中解脱出来，享受对于未来生活质量有帮助的现代化教育（幸福的教育培养学生幸福的能力）。 </p></div></div><div class="yj-left"><div style="text-align: center;"><img src="static/lvya/aboutUS/sm.png" alt="" class="yj-icon"><p style="font-size: 20px; margin: 20px 0px 10px;">使命</p><p style="width: 50px; height: 3px; background: rgb(25, 202, 129); margin: 0px auto;"></p></div><div style="line-height: 30px; color: rgb(102, 102, 102);"><p style="position: relative; padding-left: 15px;"><span class="yd"></span> 从学校现有条件出发，激发学校自身活力，提供办好学校的工具和方法论； </p><p style="position: relative; padding-left: 15px;"><span class="yd"></span> 教育目标寻找最小行动单元，实现校长育人理念，成就校长教育事业人生意义； </p><p style="position: relative; padding-left: 15px;"><span class="yd"></span> 突出学生个性发展，挖掘兴趣，使其拥有一技之长，同时可以成为升学依据； </p><p style="position: relative; padding-left: 15px;"><span class="yd"></span> 提供建设校园信息化的工具与服务，让学校可以轻松构建自己的信息化平台。 </p></div></div></div>
            <div class="about-div1" style="margin-top: 60px;"><img src="static/lvya/aboutUS/jzg.png" alt="" style="width: 100%; height: 100%;"></div>
            <div class="tip-div"><p class="tip-top">发展历程</p><p class="tip-center">DEVELOPMENT HISTORY</p><p class="tip-line"></p></div>
            <ul class="timeLine"><li><p>2015年</p><div class="con">2015年5月项目成立，启动公司注册；</div></li><li><p>2016年</p><div class="con">2016年取得国家教育部专项政策支持，并独家承接十三五规划五年重点课题，开创了教育领域“政校企研”深度合作新模式；<br><span style="padding: 20px 0px; display: inline-block;"> 2016年获批承建国内首个教育大数据应用技术国家工程实验室（联合华中师范大学共建） </span></div></li><li><p>2017年</p><div class="con">2017年完成千万级Pre-A轮投资；<br><span style="padding: 20px 0px; display: inline-block;"> 2017年产品全新升级，打造国内首家面向未来教育顶层设计和教育改革的开放式智慧校园云平台；<br></span><span style="padding: 0px 0px 20px; display: inline-block;"> 2017年12月取得国家高新技术企业认证； </span></div></li><li><p>2018年</p><div class="con">2018年在江苏、重庆等地成立业务分子公司<br><span style="padding: 20px 0px; display: inline-block;"> 2018年初完成国内大型知名综合集团珠江投资的亿级A轮战略投资； </span></div></li><li><p>2020年</p><div class="con">2020年初，上海、广东、河南、新疆、广西、宁夏分子公司陆续落地，并在当地开展业务。</div></li></ul>
            <div class="about-map">
                <div id="gpsMain2" style="width: 100%;height: 500px;"></div>
                <!-- <baidu-map v-bind:style="mapStyle" class="bm-view" ak="lR8futQyqsOmiEYw35VOSxyUVUbjGXLn" :center="center" :zoom="zoom" :scroll-wheel-zoom="true"
                    @click="getClickInfo" @moving="syncCenterAndZoom" @moveend="syncCenterAndZoom" @zoomend="syncCenterAndZoom">
                    <bm-view style="width: 100%; height:500px;"></bm-view>
                    <bm-marker :position="{lng: center.lng, lat: center.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                    </bm-marker>
                    <bm-local-search :keyword="keyword" :auto-viewport="true" style="width:0px;height:0px;overflow: hidden;"></bm-local-search>
            </baidu-map> -->
            </div>
            <div class="second_content_box"><div class="about_us_box"><div class="about_usp_box"><p style="width: 100%;"><i class="el-icon-location"></i>北京市朝阳区安慧北里秀园15号海亚大厦4层</p><p><i class="el-icon-phone"></i>010-64921605</p><p><i class="el-icon-s-promotion"></i>lvya@lvya.org</p></div><div style="display: flex; justify-content: space-around;"><div style="text-align: center;"><img src="static/lvya/aboutUS/banner3.jpg" class="second_code" style="margin-right: 10px; width: 100px !important; height: 100px !important;"><p>咨询1</p></div></div></div></div>
        </div>
    </div>
</template>

<script>
// import { BaiduMap, BmControl, BmView, BmAutoComplete, BmLocalSearch, BmMarker } from 'vue-baidu-map'
export default {
    components: {
        // BaiduMap,
        // BmControl,
        // BmView,
        // BmAutoComplete,
        // BmLocalSearch,
        // BmMarker
    },
    data() {
        return {
            showMapComponent: this.value,
            keyword: '',
            mapStyle: {
            width: '100%',
            height: this.mapHeight + 'px'
            },
            center: { lng: 116.420394, lat: 40.004966 },
            // center: { lng: 40.004888, lat: 116.420216 },
            zoom: 20,
            map: '',
        }
    },
    watch: {
      value: function(currentValue) {
        this.showMapComponent = currentValue
        if (currentValue) {
          this.keyword = ''
        }
      }
    },
    methods: {
        getClickInfo(e) {
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        },
        syncCenterAndZoom(e) {
            const { lng, lat } = e.target.getCenter()
            this.center.lng = lng
            this.center.lat = lat
            this.zoom = e.target.getZoom()
      },
    },
    created() {
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = new BMapGL.Map('gpsMain2') // 创建Map实例
        // this.map.centerAndZoom('', ) // 初始化地图,设置中心点坐标和地图级别
        // eslint-disable-next-line no-undef
        this.map.centerAndZoom(new BMapGL.Point(116.420394, 40.004966), this.zoom)
        this.map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
        // eslint-disable-next-line no-undef
        var marker1 = new BMapGL.Marker(new BMapGL.Point(116.420394, 40.004966))
        this.map.addOverlay(marker1)
    },
}
</script>

<style scoped>
.about-div2{
    width: 100%;
    margin: 0px auto;
    padding: 0 0 50px 0;
}
.tip-div {
    line-height: 70px;
    padding: 80px 0 50px 0;
    text-align: center;
}
.tip-top {
    font-size: 40px;
    color: #333;
}
.tip-center {
    font-size: 45px;
    color: #ccc;
}
.tip-line {
    width: 100px;
    height: 9px;
    background: #1BB876;
    margin: 0 auto;
    border-radius: 10px;
}
.gsjs {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.gsjs-img {
    width: 322px!important;
    height: 355px;
}
.wmdsm-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.wmdsm-div div {
    width: 23%;
    font-size: 14px;
    color: #333;
    text-align: center;
    line-height: 25px;
}
.wmdsm-div div img {
    width: 100%;
}
.timeLine {
    /* margin: 60px auto 0; */
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.timeLine li {
    background: url(data:image/png;base64,R0lGODdhAQABAPAAAMzVzAAAACwAAAAAAQABAAACAkQBADs=) repeat-y 160px 0;
    padding-bottom: 30px;
    zoom: 1;
    display: flex;
}
.timeLine li p {
    background: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQIJ/8QAHxAAAgICAgMBAAAAAAAAAAAAAQIDBAURBhIAEyEi/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANrchlcXk+K2OW8qmsT4mxIVo0FZvSkXYqhKKQHkfXYltgA6+a8cdk8XjeLV+WcVsWIMTXkC3qDM3peLsFchGJCSJvsCugQNfQfKu0MXieL2OJ8qrzQ4iFyaV4K3peLsWQGRR+JE31IbQIG/oOvCpjsXmOL1uJ8VrzS4eZwb1/q3pSLsGcCRh+5H11AXYAO/gA8D/9k=) no-repeat 152px 0;
    width: 168px;
    text-align: right;
    padding-right: 30px;
    color: #333;
}
.timeLine li .con {
    width: calc(100% - 230px);
    display: inline-block;
    padding-left: 20px;
    font-size: 15px;
}
.timeLine li:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.second_content_box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
}
.about_us_box {
    margin-top: 30px;
    border: 1px solid #ddd;
    padding: 40px 80px;
}
.about_usp_box {
    float: left;
    width: 700px;
}
.about_usp_box p {
    font-size: 16px;
    color: #555;
    float: left;
    width: 300px;
    margin-left: 30px;
    line-height: 50px;
}
.about_usp_box p>i {
    margin-right: 15px;
    font-size: 20px;
    color: #777;
}
.about-map{
        width: 1200px;
    margin: 100px auto;
    border: 1px solid #ccc;
}
.yj {
    width: 100%;
    margin: 50px auto;
    min-height: 300px;
    background: url(../../../../public/static/lvya/aboutUS/yjbg.71e37028.png) no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.yj-left:first-child{
  margin-right: 150px;
}
.yj-left {
    width: 400px;
    background: #fff;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 2px 9px #eee;
    box-shadow: 1px 2px 9px #eee;
    margin: 40px 0;
}
</style>
